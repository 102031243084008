import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RichTextRenderer = ({ content, limit = null, doubleLine = false }) => {
  if (!content) return null

  const getStructuredText = (nodes) => {
    return nodes.content
      .map((node) => {
        if (node.nodeType === 'text') return node.value
        if (node.nodeType === BLOCKS.PARAGRAPH) {
          if (doubleLine) return '\n\n' + getStructuredText(node)
          return '\n' + getStructuredText(node)
        }
        if (node.content) return getStructuredText(node)
        return ''
      })
      .join('')
  }

  const structuredText = getStructuredText(content).trim()
  const isTruncated = limit && structuredText.length > limit

  let trimmedText
  if (isTruncated) {
    const lastNewLineIndex = structuredText.lastIndexOf('\n', limit)
    if (lastNewLineIndex !== -1 && lastNewLineIndex > limit - 30) {
      trimmedText = structuredText.substring(0, lastNewLineIndex) + '...'
    } else {
      trimmedText = structuredText.substring(0, limit) + '...'
    }
  } else {
    trimmedText = structuredText
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="my-2 text-gray-800">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-2xl font-bold">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-xl font-semibold">{children}</h2>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc ml-5">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal ml-5">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          href={node.data.uri}
          className="text-blue-500 hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ),
    },
  }

  return (
    <div className="prose">
      {isTruncated ? (
        <div style={{ whiteSpace: 'pre-line' }}>{trimmedText}</div>
      ) : (
        documentToReactComponents(content, options)
      )}
    </div>
  )
}

export default RichTextRenderer
