const colors = {
  profileAvatarYellow: '#ffc442',
  profileAvatarAmberYellow: '#ffb000',
  profileAvatarLime: '#77c62b',
  profileAvatarGreen: '#009505',
  profileAvatarBlue: '#1879cd',
  profileAvatarIndigio: '#0239a4',
  profileAvatarViolet: '#7b18cd',
  profileAvatarDeepPurple: '#5b02a4',
  profileAvatarPurple: '#950077',
  profileAvatarRose: '#cd1846',
  white: '#ffffff',
  black: '#313133',
  gray200: '#f4f4f4',
  gray300: '#dbd9d6',
  gray400: '#dbd9d6',
  gray500: '#999999',
  gray600: '#67666a',
  gray800: '#565559',
  overlayBlack70: 'rgba(49, 49, 51, 0.7)',
  overlayGray20050: 'rgba(244, 244, 244, 0.5)',
  ctaPrimary: '#005B8E',
  ctaSecondary: '#67666a',
  brandYellow: '#ffb700',
  brandRed: '#c10013',
  brandOrange: '#ff4d00',
  textLinkBrand: '#e86826',
  textLinkStandaloneHover: '#DEE9E8',
  textLinkStandaloneBorder: '#DEE9E8',
  statusSuccessTint: '#e3fcef',
  statusSuccess: '#218234',
  statusWarningTint: '#fff2c4',
  statusWarning: '#ffc911',
  statusAlertTint: '#ffebe6',
  statusAlert: '#d70000',
  statusInProgressTint: '#deebff',
  statusInProgress: '#0052cc',
  statusNewTint: '#eae6ff',
  statusNew: '#5243aa',
  inputBorder: '#dbd9d6',
  inputBorderDisabled: '#ebebeb',

  // New colors after the mini-redesign
  ctaPrimaryHover: '#DEE9E8',
  ctaPrimaryLight: '#EA6E4D',
  ctaPrimaryDark: '#133960',
  ctaSecondaryHover: 'rgba(103, 102, 106, 0.12)',
  ctaSecondaryLight: '#959497',
  ctaSecondaryDarker: '#3E3D40',
  textActive: '#424140',
  textPlaceholder: '#8e8e8e',
  textDisabled: '#B3B3B3',

  // New Colors rebranding
  navy: '#133960',
  capabilityGreen: '#85BF42',
  storm: '#577B88',
  cloud: '#DEE9E8',
  atdBlue: '#005B8E',

  // utility colors
  exampleHighlight: '#CCFFFF', // used in styleguide only
  exampleBorder: 'red', // used in styleguide only
}

export interface ColorsInterface {
  color: keyof typeof colors
}

export default colors
