import axios from 'axios'

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
const BASE_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/master`

const contentfulClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
})

export default contentfulClient
