export const getConferenceDailyPageContent = /* GraphQL */ `
  query getConferenceDailyPageContent {
    pageContentAssemblyCollection(
      where: {
        contentfulMetadata: { tags: { id_contains_all: "conferenceDaily" } }
      }
      order: sys_publishedAt_DESC
    ) {
      items {
        sys {
          id
        }
        title
        body {
          json
        }
        slug
        featuredImage
      }
    }
  }
`
