export const getConferenceGallery = /* GraphQL */ `
  query getConferenceGallery {
    componentGalleryCollection(where: { code: "conferenceGallery" }) {
      items {
        title
        name
        images
      }
    }
  }
`
