import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path d="M23.9922 11.6081C23.8893 8.56386 22.634 5.67238 20.4802 3.51857C18.3264 1.36477 15.4349 0.109431 12.3907 0.00651993C10.7165 -0.0543677 9.0484 0.238924 7.4954 0.867205C5.94241 1.49549 4.53958 2.44459 3.3787 3.65243C2.25505 4.80552 1.37683 6.17444 0.797098 7.67648C0.217364 9.17852 -0.0518481 10.7825 0.00572697 12.3915C0.110948 15.5032 1.42005 18.4526 3.65716 20.618C5.89427 22.7835 8.88466 23.9959 11.9981 23.9998C15.2768 23.9998 18.3381 22.7025 20.6193 20.3471C21.7426 19.1939 22.6205 17.8249 23.2 16.3229C23.7794 14.8209 24.0484 13.217 23.9906 11.6081H23.9922ZM18.8031 18.5899C17.8864 19.5433 16.7789 20.2924 15.5528 20.7883C14.3267 21.2841 13.0098 21.5156 11.6881 21.4675C9.28536 21.3864 7.00308 20.3957 5.30308 18.6957C3.60309 16.9957 2.61232 14.7134 2.53124 12.3106C2.48549 11.0401 2.69779 9.77358 3.15532 8.58747C3.61286 7.40135 4.30613 6.32031 5.19323 5.40968C6.11005 4.4561 7.21789 3.70684 8.44426 3.21096C9.67063 2.71507 10.9879 2.48371 12.3098 2.53204C14.7126 2.61312 16.9949 3.60388 18.6949 5.30388C20.3949 7.00387 21.3857 9.28615 21.4667 11.6889C21.5125 12.9594 21.3002 14.226 20.8427 15.4121C20.3851 16.5982 19.6919 17.6793 18.8047 18.5899H18.8031Z" />
    <path d="M15.9349 11.3034L10.6143 7.48729C10.4859 7.39581 10.3349 7.3414 10.1777 7.33001C10.0205 7.31861 9.8632 7.35068 9.723 7.42269C9.5828 7.4947 9.46511 7.60389 9.38281 7.73831C9.3005 7.87272 9.25675 8.02719 9.25635 8.1848V15.817C9.25737 15.9743 9.30156 16.1283 9.38411 16.2622C9.46666 16.3962 9.5844 16.5049 9.72449 16.5765C9.86458 16.6481 10.0216 16.6799 10.1785 16.6683C10.3355 16.6568 10.4862 16.6024 10.6143 16.5111L15.9349 12.695C16.0452 12.6154 16.135 12.5107 16.197 12.3896C16.2589 12.2685 16.2912 12.1344 16.2912 11.9983C16.2912 11.8623 16.2589 11.7282 16.197 11.6071C16.135 11.486 16.0452 11.3813 15.9349 11.3017V11.3034Z" />
  </>
)

export default Icon
