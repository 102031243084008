// @react
import React from 'react'
// @libraries
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// @components
import colors from '../../bitComponents/colors/colors'
import Grid from '../../bitComponents/grid'
import Button from '../../bitComponents/button'
import Icon from '../../bitComponents/icons'

import Container from '../../bitComponents/container'
import GroupTitle from '../../bitComponents/headerGroopTitle'
import Link from '../../bitComponents/headerLink'
// @parts
import DesktopLinks from './DesktopLinks'
import MobileLinks from './MobileLinks'
// @constants
import { RouteNamesExternal } from '../../constants/routeNamesExternal'
// @assets
import logoSmall from './assets/atd-logo-small.svg'
import strings from '../../constants/strings'

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      position: 'relative',
      marginTop: '2em',
      paddingTop: '32px',
      paddingBottom: '24px',
      backgroundColor: colors.gray200,
      '& ul': {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: '56px',
        paddingBottom: '38px',
      },
    },
    border: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: `linear-gradient(90deg, ${colors.navy} 0%, ${colors.capabilityGreen} 52.98%, ${colors.storm} 98.75%)`,
    },
    logoWrap: {
      display: 'flex',
      maxWidth: '500px',
      marginBottom: '48px',
      alignItems: 'center',
      [theme.breakpoints.down(400)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '& img': {
        flexShrink: 0,
        marginRight: '16px',
      },
      '& p': {
        margin: 0,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        [theme.breakpoints.down(400)]: {
          marginTop: '8px',
        },
      },
    },
    svgWrap: {
      width: '100px',
      marginRight: '24px',
    },
    svg: {
      width: '100%',
    },
    mid: {
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    socialWrap: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '40px',
      },
    },
    socialIcons: {
      display: 'flex',
      marginBottom: '24px',
      '& a:not(:last-child)': {
        marginRight: '16px',
      },
    },
    btn: {
      backgroundColor: colors.white,
    },
    copyright: {
      marginTop: '30px',
      '& span': {
        fontSize: '12px',
        fontWeight: 500,
      },
      '& p': {
        marginTop: '9px',
        marginBottom: 0,
        fontSize: '10px',
        maxWidth: '327px',
      },
      '& ul': {
        display: 'flex',
      },
      '& li:not(:last-child)': {
        marginRight: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        '& .MuiGrid-item:last-child': {
          order: -1,
          marginBottom: '14px',
        },
      },
    },
  })
)

const Footer = () => {
  /********************************************
   *  HOOKS
   *********************************************/

  const theme = useTheme()
  const classes = useStyles()
  const desktop = useMediaQuery(theme.breakpoints.up('sm'))
  const handleMemberClick = async () => {
    await window.analytics.track('Become a Member Clicked')
  }

  /********************************************
   *  RENDER
   *********************************************/

  return (
    <footer className={classes.footer}>
      <span className={classes.border} />
      <Container maxWidth="xl">
        <div
          className={classes.logoWrap}
          onClick={() => {
            window.location.replace(RouteNamesExternal.ATD_HOME)
          }}
        >
          <div className={classes.svgWrap}>
            <img className={classes.svg} src={logoSmall} alt="ATD" />
          </div>
          <p>{strings.MISSION.DESCRIPTION}</p>
        </div>
        <Grid
          container
          justifyContent="space-between"
          className={classes.mid}
          maxWidth="xl"
        >
          <Grid item xs={12} sm="auto">
            {desktop && <DesktopLinks />}
            {!desktop && <MobileLinks />}
          </Grid>
          <Grid item xs={12} sm="auto" className={classes.socialWrap}>
            <GroupTitle>Stay Connected</GroupTitle>
            <div className={classes.socialIcons}>
              <Link href={RouteNamesExternal.SOCIAL_FACEBOOK}>
                <Icon glyph="facebook" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_TWITTER}>
                <Icon glyph="twitter" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_LINKEDIN}>
                <Icon glyph="linkedin" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_PINTEREST}>
                <Icon glyph="pinterest" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_INSTAGRAM}>
                <Icon glyph="instagram" />
              </Link>
            </div>

            <Button
              variant="secondary"
              href={RouteNamesExternal.CHECKOUT_MEMBERSHIP}
              className={classes.btn}
              onClick={() => handleMemberClick()}
            >
              Become A Member
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.copyright}
        >
          <Grid item>
            <span>
              &copy; {new Date().getFullYear()} ATD | All Rights Reserved
            </span>
            <p>
              ASTD changed its name to ATD to meet the growing needs of a
              dynamic, global profession.
            </p>
          </Grid>
          <Grid item>
            <ul>
              <li>
                <Link href={RouteNamesExternal.ATD_TOS}>Terms of Use</Link>
              </li>
              <li>
                <Link href={RouteNamesExternal.ATD_TOS_PRIVACY}>
                  Privacy Notice
                </Link>
              </li>
              <li>
                <Link href={RouteNamesExternal.ATD_COOKIE_POLICY}>
                  Cookies Policy
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
