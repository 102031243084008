import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.31 8.303C5.868 7.123 8.33 6 12 6c3.67 0 6.131 1.122 7.69 2.303a8.864 8.864 0 011.665 1.629 6.533 6.533 0 01.494.736l.03.054.01.019.003.006.001.003v.002L21 11.2l.894-.447c.07.139.106.292.106.447v.5h-2v-.227a4.822 4.822 0 00-.227-.317 6.873 6.873 0 00-1.289-1.259C17.27 8.977 15.23 8 12 8s-5.269.978-6.484 1.897a6.871 6.871 0 00-1.29 1.259 4.834 4.834 0 00-.226.317v.327H2v-.6a1 1 0 01.106-.447L3 11.2a72.467 72.467 0 01-.894-.448v-.002l.002-.003.004-.006.01-.019a4.41 4.41 0 01.131-.228c.088-.142.216-.335.392-.562a8.868 8.868 0 011.664-1.63zm15.799 3.35c0 .002 0 .002 0 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.9v-.7h2v.6l.012.046c.024.09.068.23.144.406.153.35.432.84.935 1.337.986.976 2.962 2.111 6.909 2.111 3.947 0 5.923-1.135 6.91-2.11a4.31 4.31 0 00.934-1.338A2.976 2.976 0 0020 11.8v-.6h2v.7a.996.996 0 01-.01.144L21 11.9c.99.144.99.145.99.146l-.001.002v.004l-.002.009-.004.024a3.44 3.44 0 01-.064.281 4.971 4.971 0 01-.24.682 6.306 6.306 0 01-1.363 1.963C18.877 16.435 16.353 17.7 12 17.7c-4.353 0-6.877-1.265-8.316-2.69a6.306 6.306 0 01-1.362-1.962 4.967 4.967 0 01-.292-.895 2.377 2.377 0 01-.013-.068l-.004-.024-.001-.01-.001-.003v-.002s0-.002.989-.146l-.99.144A1 1 0 012 11.9z"
      fill="currentColor"
    />
    <path
      d="M12 9c1.6 0 2.8 1.3 2.8 2.8 0 1.5-1.2 2.9-2.8 2.9-1.6 0-2.8-1.3-2.8-2.9C9.2 10.2 10.4 9 12 9zm0-2c-2.7 0-4.8 2.2-4.8 4.8 0 2.6 2.2 4.8 4.8 4.8 2.6 0 4.8-2.2 4.8-4.8C16.8 9.2 14.7 7 12 7z"
      fill="currentColor"
    />
    <path
      d="M12 11.8c.1.1 0 .2 0 .2s-.1-.1 0-.2zm0-2c-1.1 0-2.1.9-2.1 2.1 0 1.2 1 2 2.1 2s2.1-.9 2.1-2.1c0-1.2-1-2-2.1-2z"
      fill="currentColor"
    />
  </>
)

export default Icon
