import { Typography } from '@mui/material'
import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import Slider from 'react-slick'
import ComponentLoader from '../Loader/ComponentLoader'
import ErrorPage from '../Error'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sponsors: {
    cursor: 'pointer',
    height: '200px',
  },
  gallery: {
    '& img': {
      height: '120px',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
  },
  sponsorImageWrapper: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  galleryImageWrapper: {
    marginRight: '2.5px',
    marginLeft: '2.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  titleSponsors: {
    marginBottom: '0',
  },
  titleGallery: {
    marginBottom: '30px',
  },
}))

const Sliders = ({
  images,
  error,
  loading,
  title,
  slidesToShow = 1,
  slidesToScroll = 1,
  rowsToShow = 1,
  type,
}) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [lightboxImages, setLightboxImages] = useState([])

  const sliderSettings = {
    infinite: true,
    speed: 700,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    rows: rowsToShow,
    dots: true,
  }

  if (loading) return <ComponentLoader />

  if (error) return <ErrorPage />

  const openLightbox = (images, index) => {
    setLightboxImages(images)
    setCurrentImageIndex(index)
    setIsOpen(true)
  }
  return (
    <div style={{ marginBottom: '40px' }}>
      <Typography
        variant="h5"
        className={
          type === 'Sponsors' ? classes.titleSponsors : classes.titleGallery
        }
        align="center"
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div
            key={index}
            className={type === 'Sponsors' ? classes.sponsors : classes.gallery}
          >
            <div
              className={
                type === 'Sponsors'
                  ? classes.sponsorImageWrapper
                  : classes.galleryImageWrapper
              }
            >
              <img
                src={image}
                alt={`Sponsor ${index}`}
                style={{
                  width: '100%',

                  filter: 'blur(10px)',
                  transition: 'filter 0.3s ease-out',
                }}
                onClick={() => openLightbox(images, index)}
                onLoad={(e) => (e.target.style.filter = 'none')}
              />
            </div>
          </div>
        ))}
      </Slider>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[currentImageIndex]}
          nextSrc={
            lightboxImages[(currentImageIndex + 1) % lightboxImages.length]
          }
          prevSrc={
            lightboxImages[
              (currentImageIndex + lightboxImages.length - 1) %
                lightboxImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + lightboxImages.length - 1) %
                lightboxImages.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + 1) % lightboxImages.length
            )
          }
        />
      )}
    </div>
  )
}

export default Sliders
