import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      d="M12 7.1l4.9 2.1-4.9 2.1-4.9-2.1L12 7.1zM12 5c-.2 0-.3 0-.5.1L4.1 8.3c-.2.1-.6.2-.9.4l-.7.3c-.3.1-.3.3 0 .4l.7.3c.3.1.7.3.9.4l7.4 3.2h.5c.2 0 .3 0 .5-.1l6.9-3c.3-.1.7-.3.9-.4l1.2-.5c.3-.1.3-.3 0-.4l-1.2-.5c-.3-.1-.7-.3-.9-.4l-6.9-3H12z"
      fill="currentColor"
    />
    <path d="M21 9.2h-2v5.7h2V9.2z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16.1l-.832.555A1 1 0 016 16.1V9.9h2v5.818c.121.111.31.262.577.417.613.356 1.688.765 3.423.765s2.81-.409 3.423-.765c.268-.155.456-.306.577-.417V9.9h2v6.2a1 1 0 01-.168.555L17 16.1c.832.555.832.555.831.556v.001l-.002.003-.004.006-.01.013a1.844 1.844 0 01-.1.131 3.555 3.555 0 01-.261.285 5.16 5.16 0 01-1.027.77C15.49 18.409 14.065 18.9 12 18.9c-2.065 0-3.49-.491-4.427-1.035a5.16 5.16 0 01-1.027-.77 3.556 3.556 0 01-.361-.416l-.01-.013-.004-.006-.001-.003-.001-.001s-.001-.001.831-.556z"
      fill="currentColor"
    />
    <path
      d="M19.9 8.3v1.8l2.1-.9-2.1-.9zM4.1 8.3L2 9.2l2.1.9V8.3z"
      fill="currentColor"
    />
  </>
)

export default Icon
