import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <svg
    version="1.0"
    width="50px"
    height="40px"
    viewBox="0 0 110 80"
    x="0px"
    y="0px"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,51.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M224 404 c-6 -22 -10 -24 -36 -19 -29 7 -30 6 -23 -24 5 -23 3 -31
   -7 -31 -7 0 -20 -4 -28 -10 -12 -7 -12 -12 4 -29 19 -21 19 -21 0 -42 -16 -17
   -16 -22 -4 -29 8 -6 21 -10 28 -10 10 0 12 -8 7 -31 -7 -30 -6 -31 24 -24 23
   5 31 3 31 -7 0 -7 4 -20 10 -28 7 -12 12 -12 29 4 20 18 22 18 39 3 25 -22 29
   -21 36 8 5 21 12 25 34 23 24 -3 27 0 24 23 -2 19 4 29 24 38 26 11 26 12 9
   31 -17 19 -17 21 0 40 17 19 17 20 -9 31 -20 9 -26 19 -24 38 3 23 0 26 -24
   23 -22 -2 -29 2 -34 23 -7 29 -11 30 -36 8 -17 -15 -19 -15 -36 0 -25 23 -31
   21 -38 -9z"
      />
    </g>
  </svg>
)

export default Icon
