import { Grid } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import logoSmall from './assets/atd-logo-small.svg'

const useStyles = makeStyles((theme) => ({
  mediaDate: {
    color: 'rgba(155, 155, 155, 1)',
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    paddingLeft: '2px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  },
  mediaHeader: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '32px',
    textAlign: 'left',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '34px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '36px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  atdLogo: {
    width: '70px',
  },

  image: {
    width: '70px',
  },
}))

const Header = () => {
  const classes = useStyles()
  const dateNow = new Date()
  // Formate date to mm/dd/yyyy format
  const formatMonth = dateNow.toLocaleString('default', { month: 'long' })
  const formatDay = dateNow.getDate()
  const date = `${formatMonth} ${formatDay}`
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div className={classes.container}>
        <div>
          <img className={classes.atdLogo} src={logoSmall} alt="atd-logo" />
        </div>
        <div className={classes.containerText}>
          <span className={classes.mediaHeader}>Conference Daily</span>
          <span className={classes.mediaDate}>{date}</span>
        </div>
      </div>
      <Grid>
        <img src="/atd-25.png" alt="atd-small-logo" className={classes.image} />
      </Grid>
    </Grid>
  )
}

export default Header
