import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <path
    d="M12 0C5.373 0 0 5.373 0 12c0 5.084 3.162 9.43 7.627 11.178-.105-.949-.2-2.41.041-3.446.218-.936 1.408-5.964 1.408-5.964s-.359-.72-.359-1.782c0-1.668.967-2.914 2.17-2.914 1.025 0 1.519.768 1.519 1.69 0 1.029-.656 2.568-.994 3.995-.282 1.194.599 2.168 1.777 2.168 2.133 0 3.772-2.248 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.415 0-5.419 2.561-5.419 5.208 0 1.031.397 2.137.893 2.739a.357.357 0 01.083.344c-.09.379-.293 1.194-.333 1.36-.052.22-.173.267-.4.161-1.5-.697-2.436-2.888-2.436-4.648 0-3.786 2.75-7.262 7.929-7.262 4.163 0 7.397 2.966 7.397 6.93 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.36-.632-2.75-1.377 0 0-.602 2.291-.748 2.852-.27 1.042-1.003 2.349-1.492 3.146C9.57 23.812 10.762 24 12 24c6.627 0 12-5.373 12-12C24 5.373 18.627 0 12 0z"
    fill="currentColor"
  />
)

export default Icon
