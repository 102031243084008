import { Box, Container, Grid, Slide, Typography } from '@mui/material'
import Header from './Components/Header'
import Footer from './Components/Footer'
import CookieBanner from './Components/CookieBanner'

import Cookies from 'universal-cookie/es6'

import useContentful from './hooks/useContentful'
import { getConferenceDailyPageContent } from './services/queries/getConfernceDailyPosts'
import { getConferenceDailyPageContentTopStory } from './services/queries/getConfernceDailyTopStory'
import { getConferenceGallery } from './services/queries/getConferenceGallery'
import useContentfulImages from './hooks/useContentfulImages'
import BlogsSection from './Components/Blogs'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'react-image-lightbox/style.css'
import { getConferenceSponsors } from './services/queries/getConferenceSponsors'

import TopStoryCard from './Components/TopStory'
import Sliders from './Components/Sliders'
import Ad from './Ad'
import { Hidden } from '@material-ui/core'

const App = () => {
  let segmentUserId
  const cookies = new Cookies()
  if (cookies.get('CustomerNumber') !== undefined) {
    segmentUserId = cookies.get('CustomerNumber')
  } else {
    segmentUserId = cookies.get('ajs_anonymous_id')
  }

  const {
    data: contentfulAllData,
    error: contentfulAllDataError,
    loading: loadingAllData,
  } = useContentful(getConferenceDailyPageContent)

  const {
    data: dataTopStory,
    error: errorTopStory,
    loading: loadingTopStory,
  } = useContentful(getConferenceDailyPageContentTopStory)
  const {
    data: conferenceGallery,
    error: conferenceGalleryError,
    loading: loadingConferenceGallery,
  } = useContentfulImages(getConferenceGallery)

  const {
    data: conferenceSponsors,
    error: conferenceSponsorsError,
    loading: loadingSponsors,
  } = useContentfulImages(getConferenceSponsors)

  const highlightImages = conferenceGallery?.flatMap((item) =>
    item.images.map((itemImages) => itemImages.src)
  )

  const sponsors = conferenceSponsors?.flatMap((item) =>
    item.images.map((itemImages) => itemImages.src)
  )

  let sponsorsTitle = 'Sponsors'
  let highlightsTitle = 'Highlights'

  conferenceSponsors[0]?.title && (sponsorsTitle = conferenceSponsors[0]?.title)
  conferenceGallery[0]?.title && (highlightsTitle = conferenceGallery[0]?.title)

  return (
    <Slide direction="up" in={true}>
      <Grid>
        <Grid>
          <Grid>
            <Grid
              sx={{
                paddingTop: '30px',
                paddingBottom: '30px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <Header />
            </Grid>
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TopStoryCard
                    dataTopStory={dataTopStory}
                    error={errorTopStory}
                    loading={loadingTopStory}
                    segmentUserId={segmentUserId}
                  />

                  <Box my={4}>
                    <Typography variant="h5" fontWeight="bold">
                      More News
                    </Typography>
                  </Box>

                  <BlogsSection
                    content={contentfulAllData}
                    error={contentfulAllDataError}
                    loading={loadingAllData}
                    segmentUserId={segmentUserId}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      position: 'sticky',
                      top: 20,
                      maxHeight: 'calc(100vh - 40px)',
                      paddingX: { xs: 0, sm: 2 },
                    }}
                  >
                    <Sliders
                      images={sponsors}
                      error={conferenceSponsorsError}
                      loading={loadingSponsors}
                      title={sponsorsTitle}
                      slidesToShow={3}
                      slidesToScroll={2}
                      type="Sponsors"
                    />

                    <Sliders
                      images={highlightImages}
                      error={conferenceGalleryError}
                      loading={loadingConferenceGallery}
                      title={highlightsTitle}
                      slidesToShow={2}
                      slidesToScroll={2}
                      rowsToShow={2}
                      type="Highlights"
                    />

                    <Hidden smDown>
                      <Box
                        width="100%"
                        display={'flex'}
                        justifyContent="center"
                      >
                        <Ad />
                      </Box>
                    </Hidden>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid>
            <Footer />
          </Grid>
        </Grid>
        <CookieBanner />
      </Grid>
    </Slide>
  )
}

export default App
