import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <circle cx="8" cy="8" r="8" fill="#7C7C7C" />
    <path
      fill="#fff"
      d="M4.51 9.7a4.82 4.82 0 01-.29-1.43A3.79 3.79 0 018.86 4.6l.7-.7a4.65 4.65 0 00-5.7 6.43l-.94-.18-.17.84 2.3.46.45-2.3-.84-.17-.15.72zM13.25 5.66l-2.3-.46-.45 2.3.84.17.16-.83a4 4 0 01.3 1.45 3.79 3.79 0 01-4.66 3.67l-.69.7a4.65 4.65 0 005.93-5.93c-.04-.15-.1-.27-.16-.4l.86.17.17-.84z"
    />
  </>
)

export default Icon
