export const getConferenceSponsors = /* GraphQL */ `
  query getConferenceSponsors {
    componentGalleryCollection(where: { code: "conferenceSponsors" }) {
      items {
        title
        name
        images
      }
    }
  }
`
