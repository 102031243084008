import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import colors from '../colors/colors'
// @ts-ignore
import WhitneyBookTtf from './fonts/whitneyhtf-book-webfont.ttf'
// @ts-ignore
import WhitneyBookWoff from './fonts/whitneyhtf-book-webfont.woff'
// @ts-ignore
import WhitneyBookWoff2 from './fonts/whitneyhtf-book-webfont.woff2'
// @ts-ignore
import WhitneyMediumTtf from './fonts/whitneyhtf-medium-webfont.ttf'
// @ts-ignore
import WhitneyMediumWoff from './fonts/whitneyhtf-medium-webfont.woff'
// @ts-ignore
import WhitneyMediumWoff2 from './fonts/whitneyhtf-medium-webfont.woff2'
// @ts-ignore
import WhitneySemiboldTtf from './fonts/whitneyhtf-semibold-webfont.ttf'
// @ts-ignore
import WhitneySemiboldWoff from './fonts/whitneyhtf-semibold-webfont.woff'
// @ts-ignore
import WhitneySemiboldWoff2 from './fonts/whitneyhtf-semibold-webfont.woff2'

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    switchToMobileHeader: true
  }
}

const whitney = {
  fontFamily: 'Whitney',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${WhitneyBookWoff2 as string}) format('woff2'),
    url(${WhitneyBookWoff as string}) format('woff'),
    url(${WhitneyBookTtf as string}) format('ttf')
  `,
}

const whitneyMedium = {
  fontFamily: 'Whitney',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    url(${WhitneyMediumWoff2 as string}) format('woff2'),
    url(${WhitneyMediumWoff as string}) format('woff'),
    url(${WhitneyMediumTtf as string}) format('ttf')
  `,
}

const whitneySemibold = {
  fontFamily: 'Whitney',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    url(${WhitneySemiboldWoff2 as string}) format('woff2'),
    url(${WhitneySemiboldWoff as string}) format('woff'),
    url(${WhitneySemiboldTtf as string}) format('ttf')
  `,
}

export const globals = {
  spacer: {
    xxs: '2px',
    xs: '8px',
    s: '16px',
    m: '24px',
    l: '40px',
    xl: '48px',
    xxl: '64px',
  },
  fontSize: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '22px',
    xxl: '32px',
  },
}

export const gradients = {
  brand: `linear-gradient(90deg, ${colors.cloud}  0%, ${colors.atdBlue} 52.98%, ${colors.navy} 98.75%)`,
}

export const animations = makeStyles({
  animateBackground: {
    animation: `$AnimationName 30s ease infinite`,
    backgroundSize: '200% 200%',
  },
  '@keyframes AnimationName': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
})

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
      switchToMobileHeader: 920,
    },
  },
  typography: {
    fontFamily: 'Whitney, Arial',
  },
  palette: {
    text: {
      primary: colors.ctaSecondary,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [whitney, whitneyMedium, whitneySemibold],
      },
    },
    MuiTableCell: {
      root: {
        padding: '28px 24px 24px',
      },
      head: {
        padding: '15px 24px',
        textTransform: 'uppercase',
        fontWeight: 600,
      },
    },
    MuiTableContainer: {
      root: {
        overflow: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: colors.inputBorder,
          transition: 'border-color 0.3s ease',
        },
        '&:hover $notchedOutline': {
          borderColor: colors.textActive,
        },
        '&$focused $notchedOutline': {
          borderColor: colors.textActive,
          borderWidth: '1px',
        },
        '& legend': {
          maxWidth: 0,
        },
      },
      multiline: {
        padding: '12.5px 16px',
      },
      input: {
        padding: '12.5px 16px',
        color: colors.textActive,
      },
    },
    MuiSelect: {
      root: {
        '&$disabled ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.inputBorderDisabled,
        },
      },
      select: {
        '&$disabled': {
          backgroundColor: colors.inputBorderDisabled,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        color: colors.textPlaceholder,
        top: '50%',
        transform: 'translate(16px, -50%)',
        '&$shrink': {
          opacity: 0,
          transform: 'translate(16px, -50%)',
        },
        '&$focused': {
          opacity: 0,
          transform: 'translate(16px, -50%)',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.ctaPrimary,
        '&$disabled': {
          backgroundColor: 'rgb(235, 235, 235)',
          color: 'rgb(179, 179, 179)',
        },
      },
      checked: {},
      indeterminate: {},
      colorSecondary: {
        '&$checked': {
          color: colors.ctaPrimary,
        },
      },
    },
    MuiRadio: {
      root: {
        color: colors.ctaSecondary,
        '&$disabled': {
          backgroundColor: 'rgb(235, 235, 235)',
          color: 'rgb(179, 179, 179)',
        },
      },
      checked: {},
      colorSecondary: {
        '&$checked': {
          color: colors.ctaPrimary,
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: '4px',
        backgroundColor: colors.ctaPrimary,
      },
    },
    MuiTab: {
      root: {
        padding: '8px 16px',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'none',
      },
      wrapper: {
        paddingBottom: '10px',
      },
      textColorInherit: {
        minWidth: '0',
        marginRight: '8px',
        color: colors.ctaSecondary,
        opacity: 1,
        '&$selected': {
          fontWeight: 600,
          color: colors.ctaPrimary,
        },
      },
    },
  },
})

export default theme
