import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12c0-4.4-3.6-8-8-8s-8 3.6-8 8c0 2.017.757 3.866 2 5.277V15c0-.527.18-1.044.568-1.432.382-.382.89-.563 1.408-.568A5.012 5.012 0 017 10c0-2.8 2.2-5 5-5s5 2.2 5 5c0 1.134-.36 2.17-.976 3 .519.005 1.026.186 1.408.568.388.388.568.905.568 1.432v2.277A7.963 7.963 0 0020 12zm-2 7.987c2.425-1.83 4-4.733 4-7.987 0-5.5-4.5-10-10-10S2 6.5 2 12c0 3.254 1.575 6.157 4 7.987V20h.018A9.936 9.936 0 0012 22a9.936 9.936 0 005.982-2H18v-.013zm-2-1.066V15H8v3.921A7.932 7.932 0 0012 20a7.932 7.932 0 004-1.079zM12 7c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
      fill="currentColor"
    />
  </>
)

export default Icon
