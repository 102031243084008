// @react
import React, { ReactElement, MouseEvent } from 'react'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
// @components
import Icons from '../icons'
import colors from '../colors/colors'
import icons from '../icons/getIcons'
import theme from '../theme'

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    border: 0,
    borderRadius: '3em',
    textTransform: 'none',
    transition: 'all 0.2s ease',
    minWidth: 'auto',
    '& >.MuiTouchRipple-root': {
      display: 'none',
    },
    '& svg path': {
      transition: 'all 0.2s ease',
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  glyph: {
    marginLeft: '6px',
  },
  sizeSmall: {
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '18px',
    '& svg': {
      height: '16px',
      width: '16px',
    },
  },
  sizeMedium: {
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '20px',
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  sizeLarge: {
    padding: '12px 24px',
    fontSize: '18px',
    lineHeight: '20px',
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  variantPrimary: {
    color: colors.white,
    backgroundColor: colors.ctaPrimary,
    border: `2px solid transparent`,
    '& svg path': {
      fill: colors.white,
    },
    '&:hover': {
      color: colors.ctaPrimaryDark,
      borderColor: colors.ctaPrimaryDark,
      backgroundColor: colors.ctaPrimaryHover,
      '& svg path': {
        fill: colors.ctaPrimary,
      },
    },
    '&:active': {
      color: colors.white,
      border: `2px solid ${colors.ctaPrimary}`,
      boxShadow: `inset 0 0 0 2px ${colors.white}`,
      backgroundColor: colors.ctaPrimary,
      '& svg path': {
        fill: colors.white,
      },
      '&:hover': {
        '& svg path': {
          fill: colors.white,
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px',
        outlineOffset: 1,
        '& svg path': {
          fill: colors.white,
        },
      },
    },
    '&.Mui-disabled': {
      backgroundColor: colors.inputBorderDisabled,
      color: colors.textDisabled,
      border: 'none',
      boxShadow: 'none',
    },
  },
  variantSecondary: {
    color: colors.ctaSecondary,
    backgroundColor: `${colors.white}`,
    border: 'solid 2px transparent',
    boxShadow: `inset 0 0 0 1px ${colors.ctaSecondary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: colors.ctaSecondaryHover,
      border: `solid 2px ${colors.ctaSecondaryDarker}`,
      color: colors.ctaSecondaryDarker,
    },
    '&:active': {
      backgroundColor: colors.ctaSecondary,
      boxShadow: `inset 0 0 0 2px ${colors.white}`,
      border: `solid 2px ${colors.ctaSecondary}`,
      color: colors.white,
      '& svg path': {
        fill: colors.white,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px',
        outlineOffset: 1,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      boxShadow: `inset 0 0 0 1px ${colors.inputBorderDisabled}`,
    },
  },
  variantTertiary: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: `2px solid transparent`,
    color: colors.ctaSecondary,
    '&:hover': {
      backgroundColor: colors.ctaSecondaryHover,
      boxShadow: 'none',
      color: colors.ctaSecondaryDarker,
    },
    '&:active': {
      backgroundColor: colors.ctaSecondary,
      color: colors.white,
      '& svg path': {
        fill: colors.white,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px',
        outlineOffset: 1,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      color: colors.textDisabled,
    },
  },
  variantPrimaryTextLink: {
    color: colors.ctaPrimary,
    padding: '6px 4px',
    fontWeight: 500,
    boxShadow: 'none',
    '&:hover': {
      color: colors.ctaPrimaryLight,
      background: 'none',
      boxShadow: 'none',
    },
    '&:active': {
      color: colors.ctaPrimaryLight,
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px',
        outlineOffset: 1,
      },
    },
  },
  variantSecondaryTextLink: {
    color: colors.ctaSecondary,
    padding: '6px 4px',
    fontWeight: 500,
    boxShadow: 'none',
    '&:hover': {
      color: colors.ctaPrimary,
      background: 'none',
      boxShadow: 'none',
    },
    '&:active': {
      color: colors.ctaPrimary,
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px',
        outlineOffset: 1,
      },
    },
  },
  variantIcon: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.ctaSecondaryHover,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: colors.ctaSecondary,
      '& svg path': {
        fill: colors.white,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px',
        outlineOffset: 1,
      },
    },
    '&$sizeSmall': {
      padding: '6px',
    },
    '&$sizeMedium': {
      padding: '8px',
    },
    '&$sizeLarge': {
      padding: '12px',
    },
  },
  isIconSmall: {
    '& $glyph': {
      marginLeft: 0,
      transform: 'translateX(1px)',
    },
  },
  isIconOutsideContent: {
    '& $content': {
      width: 'auto',
    },
  },
  isActive: {
    '&$variantPrimary': {
      color: colors.white,
      border: `2px solid ${colors.ctaPrimary}`,
      boxShadow: `inset 0 0 0 2px ${colors.white}`,
      backgroundColor: colors.ctaPrimary,
      '& svg path': {
        fill: colors.white,
      },
    },
    '&$variantSecondary': {
      color: colors.white,
      backgroundColor: colors.ctaSecondaryDarker,
      boxShadow: `inset 0 0 0 2px ${colors.white}`,
      border: `solid 2px ${colors.ctaSecondaryDarker}`,
      '& svg path': {
        fill: colors.white,
      },
    },
    '&$variantTertiary': {
      color: colors.white,
      backgroundColor: colors.ctaSecondaryDarker,
      '& svg path': {
        fill: colors.white,
      },
    },
    '&$variantPrimaryTextLink': {
      color: colors.ctaPrimaryLight,
    },
    '&$variantSecondaryTextLink': {
      color: colors.ctaPrimary,
    },
    '&$variantIcon': {
      backgroundColor: colors.ctaSecondaryDarker,
      '& svg path': {
        fill: colors.white,
      },
    },
  },
  isDisabled: {
    '&$variantPrimary': {
      backgroundColor: colors.inputBorderDisabled,
      color: colors.textDisabled,
      border: 'none',
      boxShadow: 'none',
      '& svg path': {
        fill: colors.textDisabled,
      },
    },
    '&$variantSecondary': {
      backgroundColor: 'transparent',
      boxShadow: `inset 0 0 0 1px ${colors.inputBorderDisabled}`,
      '& svg path': {
        fill: colors.textDisabled,
      },
    },
    '&$variantTertiary': {
      color: colors.textDisabled,
      backgroundColor: 'transparent',
      '& svg path': {
        fill: colors.textDisabled,
      },
    },
    '&$variantPrimaryTextLink': {
      color: colors.textDisabled,
      '& svg path': {
        fill: colors.textDisabled,
      },
    },
    '&$variantSecondaryTextLink': {
      color: colors.textDisabled,
      '& svg path': {
        fill: colors.textDisabled,
      },
    },
    '&$variantIcon': {
      color: colors.textDisabled,
      backgroundColor: 'transparent',
      '& svg path': {
        fill: colors.textDisabled,
      },
    },
  },
  alignmentSpaceBetween: {
    '& > span': {
      justifyContent: 'space-between',
    },
    '& span > span': {
      justifyContent: 'space-between',
    },
  },
  alignmentSpaceEvenly: {
    '& > span': {
      justifyContent: 'space-evenly',
    },
    '& span > span': {
      justifyContent: 'space-evenly',
    },
  },
  alignmentLeft: {
    '& > span': {
      justifyContent: 'flex-start',
    },
    '& span > span': {
      justifyContent: 'flex-start',
    },
  },
  alignmentRight: {
    '& > span': {
      justifyContent: 'flex-end',
    },
    '& span > span': {
      justifyContent: 'flex-end',
    },
  },
  alignmentCenter: {
    '& > span': {
      justifyContent: 'center',
    },
    '& span > span': {
      justifyContent: 'center',
    },
  },
})

type PropsType = {
  /** React Node or text */
  children?: ReactElement[] | ReactElement | string
  /** ClassName */
  className?: string
  /** ClassNames */
  classNames?: {
    root?: string
    content?: string
    glyph?: string
  }
  /** Link URL */
  href?: string
  /** Full width */
  fullWidth?: boolean
  /** One of the following: 'small' | 'medium' | 'large' */
  size?: 'small' | 'medium' | 'large'
  /** One of the following: 'primary' | 'secondary' | 'tertiary' | 'arrow' | 'textLink' */
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'primaryTextLink'
    | 'secondaryTextLink'
    | 'icon'
  /** One of the following: 'space-between' | 'space-even' | 'left' | 'right' | 'center' */
  alignment?: 'space-between' | 'space-evenly' | 'left' | 'right' | 'center'
  /** Icon to display when variant == link */
  linkIcon?: keyof typeof icons
  /** Makes button non-intractable */
  disabled?: boolean
  /** Makes button show active state by default */
  active?: boolean
  /** Link target, ie '_blank' */
  target?: string
  /** OnClick handler */
  onClick?: (e?: MouseEvent) => void
  onMouseEnter?: (e?: MouseEvent) => void
  onMouseLeave?: (e?: MouseEvent) => void
}

const Button = ({
  className,
  classNames,
  href,
  target,
  children,
  size,
  variant,
  disabled,
  active,
  fullWidth,
  alignment,
  onClick,
  onMouseEnter,
  onMouseLeave,
  linkIcon,
}: PropsType): ReactElement => {
  const classes = useStyles()

  const isInternalLink = href?.startsWith('/')

  return (
    <MuiButton
      className={clsx(
        classes.root,
        {
          [classes.alignmentCenter]: alignment === 'center',
          [classes.alignmentLeft]: alignment === 'left',
          [classes.alignmentRight]: alignment === 'right',
          [classes.alignmentSpaceBetween]: alignment === 'space-between',
          [classes.alignmentSpaceEvenly]: alignment === 'space-evenly',
          [classes.sizeSmall]: size === 'small',
          [classes.sizeMedium]: size === 'medium',
          [classes.sizeLarge]: size === 'large',
          [classes.variantPrimary]: variant === 'primary',
          [classes.variantSecondary]: variant === 'secondary',
          [classes.variantTertiary]: variant === 'tertiary',
          [classes.variantPrimaryTextLink]: variant === 'primaryTextLink',
          [classes.variantSecondaryTextLink]: variant === 'secondaryTextLink',
          [classes.variantIcon]: variant === 'icon',
          [classes.isIconSmall]: linkIcon === 'caret-down-small',
          [classes.isIconOutsideContent]: linkIcon,
          [classes.isDisabled]: disabled,
          [classes.isActive]: active,
          [className || '']: className,
        },
        classNames?.root
      )}
      component={href ? (isInternalLink ? Link : 'a') : 'button'}
      href={!isInternalLink ? href : undefined}
      to={isInternalLink ? href : undefined}
      // target={target || (href && !isInternalLink ? '_blank' : undefined)}
      // rel={target === '_blank' || (href && !isInternalLink) ? 'noreferrer noopener' : undefined}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children && (
        <>
          <span className={clsx(classes.content, classNames?.content)}>
            {children}
          </span>
        </>
      )}

      {linkIcon && (
        <Icons
          className={clsx(classes.glyph, classNames?.glyph)}
          glyph={linkIcon}
        />
      )}
    </MuiButton>
  )
}

Button.defaultProps = {
  size: 'medium',
  variant: 'primary',
  alignment: 'center',
  disabled: false,
  fullWidth: false,
  className: '',
}

export default Button
