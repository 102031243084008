import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    position: 'relative',
    right: '2%',
    width: '80%',
    height: '80%',
    stroke: 'rgba(0, 0, 0, 0.75)',
    '&focus, &:hover': {
      outline: 'none',
      boxShadow: '0 0 12px -8px rgba(0, 0, 0, 0.6)',
      backgroundColor: 'black',
      $bellIconGroup: {
        animation: '$animateGroup 2.3s',
      },
    },
  },
  bellIconGroup: {
    transformOrigin: '30% 2px',
    transform: 'rotate(-8deg)',
    animationFillMode: 'both',
    animationIterationCount: '1',
    animationTimingFunction: 'ease-in-out',
    animation: '$animateGroup 2.3s',
  },
  bellIconBall: {
    transformOrigin: '50% 2px',
    transform: 'translateX(-6.5%)',
    animationFillMode: 'both',
    animationIterationCount: '1',
    animationTimingFunction: 'ease-in-out',
    animation: '$animateBall 2.3s',
  },
  '@keyframes animateGroup': {
    '0%, 100% ': {
      transform: 'rotate(-8deg)',
    },
    '20%': {
      transform: 'rotate(0deg)',
    },
    '40%': {
      transform: 'rotate(-20deg)',
    },
    '55%': {
      transform: 'rotate(20deg)',
    },
    '65%': {
      transform: 'rotate(-20deg)',
    },
    '74%': {
      transform: 'rotate(8deg)',
    },
    '85%': {
      transform: 'rotate(-15deg)',
    },
    '90%': {
      transform: 'rotate(-7deg)',
    },
  },

  '@keyframes animateBall': {
    '0%, 100%': {
      transform: 'translateX(-6.5%)',
    },
    '20%': {
      transform: 'translateX(0%)',
    },
    '25%': {
      transform: 'translateX(-1%)',
    },
    '40%': {
      transform: 'translateX(11%)',
    },
    '55%': {
      transform: 'translateX(-11%)',
    },
    '60%': {
      transform: 'translateX(0%)',
    },
    '68%': {
      transform: 'translateX(10%)',
    },
    '78%': {
      transform: 'translateX(-11%)',
    },
    '90%': {
      transform: 'translateX(11%)',
    },
    '98%': {
      transform: 'translateX(-11%)',
    },
  },
})

const Icon = (): ReactElement => {
  const classes = useStyles()
  return (
    <svg
      className={classes.root}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="50px"
      height="30px"
      viewBox="0 0 90 50"
      enableBackground="new 0 0 50 30"
    >
      <g className={classes.bellIconGroup}>
        <path
          className={classes.bellIconBall}
          fillRule="evenodd"
          strokeWidth="1.5"
          clipRule="evenodd"
          fill="none"
          stroke="#currentColor"
          strokeMiterlimit="10"
          d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          stroke="#currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z"
        />
      </g>
    </svg>
  )
}

export default Icon
