import Account from './svg/Account'
import Add from './svg/Add'
import Alert from './svg/Alert'
import ArrowRight from './svg/ArrowRight'
import Articles from './svg/Articles'
import Bookmark from './svg/Bookmark'
import BookmarkFill from './svg/BookmarkFill'
import Calendar from './svg/Calendar'
import CaretDown from './svg/CaretDown'
import CaretDownSmall from './svg/CaretDownSmall'
import CaretLeft from './svg/CaretLeft'
import CaretLeftSmall from './svg/CaretLeftSmall'
import CaretRight from './svg/CaretRight'
import CaretRightSmall from './svg/CaretRightSmall'
import CaretUp from './svg/CaretUp'
import CaretUpSmall from './svg/CaretUpSmall'
import Cart from './svg/Cart'
import Certifications from './svg/Certifications'
import CheckMark from './svg/CheckMark'
import Close from './svg/Close'
import Comment from './svg/Comment'
import Conferences from './svg/Conferences'
import Courses from './svg/Courses'
import Download from './svg/Download'
import Edit from './svg/Edit'
import Ellipsis from './svg/Ellipsis'
import Email from './svg/Email'
import External from './svg/External'
import Facebook from './svg/Facebook'
import FilterAsc from './svg/FilterAsc'
import FilterDefault from './svg/FilterDefault'
import FilterDesc from './svg/FilterDesc'
import Globe from './svg/Globe'
import HamburgerNav from './svg/HamburgerNav'
import Instagram from './svg/Instagram'
import InProgress from './svg/InProgress'
import Linkedin from './svg/LinkedIn'
import LockOff from './svg/LockOff'
import LockOn from './svg/LockOn'
import Magnify from './svg/Magnify'
import Masters from './svg/Masters'
import Notification from './svg/Notification'
import NotificationActive from './svg/NotificationActive'
import PerformanceResources from './svg/PerformanceResources'
import Pinterest from './svg/Pinterest'
import Placeholder from './svg/Placeholder'
import PlaceholderSquare from './svg/PlaceholderSquare'
import Podcast from './svg/Podcast'
import Publications from './svg/Publications'
import Reply from './svg/Reply'
import Settings from './svg/Settings'
import Share from './svg/Share'
import Star from './svg/Star'
import Subtract from './svg/Subtract'
import Tooltip from './svg/Tooltip'
import Twitter from './svg/Twitter'
import Video from './svg/Video'
import VisibilityOff from './svg/VisibilityOff'
import VisibilityOn from './svg/VisibilityOn'
import Webcast from './svg/Webcast'
import Union from './svg/Union'
import Clock from './svg/Clock'
import Bell from './svg/Bell'
import VideoAlt from './svg/VideoAlt'
import NewTab from './svg/NewTab'
import Certificate from './svg/Certificate'
import Badge from './svg/Badge'
import View from './svg/View'

const icons = {
  'arrow-right': ArrowRight,
  'bookmark-fill': BookmarkFill,
  'caret-down': CaretDown,
  'caret-down-small': CaretDownSmall,
  'caret-left': CaretLeft,
  'caret-left-small': CaretLeftSmall,
  'caret-right': CaretRight,
  'caret-right-small': CaretRightSmall,
  'caret-up': CaretUp,
  'caret-up-small': CaretUpSmall,
  'filter-asc': FilterAsc,
  'filter-desc': FilterDesc,
  'hamburger-nav': HamburgerNav,
  'in-progress': InProgress,
  'lock-off': LockOff,
  'lock-on': LockOn,
  'notification-active': NotificationActive,
  'performance-resources': PerformanceResources,
  'placeholder-square': PlaceholderSquare,
  'visibility-off': VisibilityOff,
  'visibility-on': VisibilityOn,
  'video-alt': VideoAlt,
  account: Account,
  bell: Bell,
  badge: Badge,
  add: Add,
  alert: Alert,
  articles: Articles,
  bookmark: Bookmark,
  calendar: Calendar,
  cart: Cart,
  certificate: Certificate,
  certifications: Certifications,
  checkmark: CheckMark,
  close: Close,
  clock: Clock,
  comment: Comment,
  conferences: Conferences,
  courses: Courses,
  download: Download,
  edit: Edit,
  ellipsis: Ellipsis,
  email: Email,
  external: External,
  facebook: Facebook,
  filter: FilterDefault,
  globe: Globe,
  instagram: Instagram,
  linkedin: Linkedin,
  magnify: Magnify,
  masters: Masters,
  newtab: NewTab,
  notification: Notification,
  pinterest: Pinterest,
  placeholder: Placeholder,
  podcast: Podcast,
  publications: Publications,
  reply: Reply,
  settings: Settings,
  share: Share,
  star: Star,
  subtract: Subtract,
  tooltip: Tooltip,
  twitter: Twitter,
  video: Video,
  view: View,
  webcast: Webcast,
  union: Union,
}

export default icons
