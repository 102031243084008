import React, { ReactElement } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const SkeletonLoader = (): ReactElement => {
  return (
    <>
      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={10} width="80%" />
    </>
  )
}

export default SkeletonLoader
