import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.381 4.557A1.993 1.993 0 0018 4c-1.06 0-1.934.835-1.996 1.88l.007-.004a2 2 0 103.37-1.32zm-4.393 4.067A3.994 3.994 0 0018 10c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4c0 .287.03.567.089.838L9.013 9.376A3.994 3.994 0 006 8c-2.2 0-4 1.8-4 4s1.8 4 4 4a3.994 3.994 0 003.013-1.376l5.076 2.538c-.058.27-.089.551-.089.838 0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4a3.994 3.994 0 00-3.012 1.376L9.91 12.838a3.978 3.978 0 000-1.676l5.077-2.538zM5.992 14H6c.406 0 .784-.122 1.1-.332l-.007-.003a1.998 1.998 0 000-3.33l.007-.003A1.985 1.985 0 006 10h-.015a2 2 0 100 4h.007zM18 20c-.517 0-.99-.199-1.346-.524a2 2 0 112.822-2.822c.325.356.524.829.524 1.346 0 1.1-.9 2-2 2z"
    fill="currentColor"
  />
)

export default Icon
