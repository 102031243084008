import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20ZM13 11.6V6C13 5.4 12.6 5 12 5C11.4 5 11 5.4 11 6V12C11 12.1 11 12.3 11.1 12.4C11.2 12.5 11.2 12.6 11.3 12.7L14.3 15.7C14.5 15.9 14.8 16 15 16C15.2 16 15.5 15.9 15.7 15.7C16.1 15.3 16.1 14.7 15.7 14.3L13 11.6Z"
      fill="black"
    />
  </>
)

export default Icon
