import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5H14V7C10.4735 7 7.55612 9.60771 7.07089 13H6V5ZM7.07089 15H4V3H16V7.28988C18.8915 8.15043 21 10.829 21 14C21 17.866 17.866 21 14 21C10.4735 21 7.55612 18.3923 7.07089 15ZM16 9.41604C17.7659 10.1876 19 11.9497 19 14C19 16.7614 16.7614 19 14 19C11.581 19 9.56329 17.2822 9.10002 15H16V9.41604ZM14 9C11.581 9 9.56329 10.7178 9.10002 13H14V9Z"
      fill="#424140"
    />
  </>
)

export default Icon
