import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm3.03-18.105c-.79-.036-1.028-.044-3.03-.044-2.003 0-2.24.008-3.03.044-.732.033-1.13.155-1.394.258-.35.136-.6.298-.862.561a2.326 2.326 0 00-.56.862c-.104.265-.226.662-.259 1.393-.036.791-.044 1.028-.044 3.03 0 2.003.008 2.24.044 3.031.033.731.155 1.129.258 1.393.136.35.298.6.561.862.263.263.512.425.862.561.265.103.662.225 1.393.258.79.036 1.028.044 3.03.044 2.003 0 2.24-.008 3.031-.044.731-.033 1.129-.155 1.393-.258.35-.136.6-.298.862-.56.263-.263.425-.513.561-.863.103-.264.225-.662.258-1.393.036-.79.044-1.028.044-3.03 0-2.003-.008-2.24-.044-3.03-.033-.732-.155-1.13-.258-1.394a2.32 2.32 0 00-.56-.862 2.327 2.327 0 00-.863-.56c-.264-.104-.662-.226-1.393-.259zm-6.123-1.35C9.707 4.51 9.963 4.5 12 4.5s2.292.009 3.092.045c.798.037 1.343.163 1.82.349.494.191.912.448 1.329.865.417.416.673.835.865 1.328.185.477.312 1.022.349 1.82.036.8.045 1.056.045 3.093s-.009 2.292-.045 3.092c-.037.798-.163 1.343-.349 1.82a3.676 3.676 0 01-.865 1.329 3.672 3.672 0 01-1.328.865c-.477.185-1.023.312-1.82.348-.8.037-1.056.046-3.093.046s-2.292-.01-3.092-.046c-.798-.036-1.344-.163-1.82-.348a3.675 3.675 0 01-1.329-.865 3.674 3.674 0 01-.865-1.329c-.186-.477-.312-1.022-.349-1.82-.036-.8-.045-1.056-.045-3.092 0-2.037.009-2.293.045-3.093.037-.798.163-1.343.349-1.82.191-.493.448-.911.865-1.328a3.673 3.673 0 011.328-.865c.477-.186 1.022-.312 1.82-.349zM8.15 12a3.851 3.851 0 117.702 0 3.851 3.851 0 01-7.702 0zM9.5 12a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm6.504-3.103a.9.9 0 100-1.8.9.9 0 000 1.8z"
    fill="currentColor"
  />
)

export default Icon
