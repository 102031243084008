import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.6 2.2c-.1-.1-.4-.2-.6-.2h-.2L12 3.5 3.2 2H3c-.2 0-.5.1-.6.2-.3.2-.4.5-.4.8v16.5c0 .5.4.9.8 1l9 1.5h.4l9-1.5c.4-.1.8-.5.8-1V3c0-.3-.1-.6-.4-.8zM20 18.7l-7.5 1.2V5.4L20 4.2v14.5zM11.5 5.4v14.5L4 18.7V4.2l7.5 1.2zm7.5.126l-5.5.917v1.014L19 6.54V5.526zm0 2.8l-5.5.917v1.014L19 9.34V8.326zm0 2.7l-5.5.917v1.014l5.5-.917v-1.014zm0 2.8l-5.5.917v1.014l5.5-.917v-1.014zm0 2.7l-5.5.917v1.014l5.5-.917v-1.014zM5 17.54l5.5.917v-1.014L5 16.526v1.014zm0-2.7l5.5.917v-1.014L5 13.826v1.014zm5.5-1.883L5 12.04v-1.014l5.5.917v1.014zm0-2.7V9.243L5 8.326V9.34l5.5.917zM5 6.54l5.5.917V6.443L5 5.526V6.54z"
    fill="currentColor"
  />
)

export default Icon
