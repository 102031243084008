import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.31 8.303C5.868 7.123 8.33 6 12 6c3.67 0 6.131 1.122 7.69 2.303a8.863 8.863 0 011.665 1.629 6.535 6.535 0 01.494.736l.03.054.01.019.003.006.001.003v.002L21 11.2l.894-.447c.07.139.106.292.106.447v.6h-2v-.327a4.795 4.795 0 00-.227-.317 6.874 6.874 0 00-1.289-1.259C17.27 8.977 15.23 8 12 8s-5.269.978-6.484 1.897a6.871 6.871 0 00-1.29 1.259 4.807 4.807 0 00-.226.317v.327H2v-.6a1 1 0 01.106-.447L3 11.2a72.467 72.467 0 01-.894-.448v-.002l.002-.003.004-.006.01-.019a4.337 4.337 0 01.131-.228c.088-.142.216-.335.392-.562a8.868 8.868 0 011.664-1.63zm15.799 3.35c0 .002 0 .002 0 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.9v-.7h2v.6l.012.046c.024.09.068.23.144.406.153.35.432.84.935 1.337.986.976 2.962 2.111 6.909 2.111 3.947 0 5.923-1.135 6.91-2.11.502-.499.782-.988.934-1.338A2.972 2.972 0 0020 11.8v-.6h2v.7a.996.996 0 01-.01.144L21 11.9c.99.144.99.145.99.146l-.001.002v.004l-.002.009-.004.024a3.456 3.456 0 01-.064.281 4.971 4.971 0 01-.24.682 6.307 6.307 0 01-1.363 1.963C18.877 16.435 16.353 17.7 12 17.7c-4.353 0-6.877-1.265-8.316-2.69a6.307 6.307 0 01-1.362-1.962 4.967 4.967 0 01-.292-.895 2.377 2.377 0 01-.013-.068l-.004-.024-.001-.01-.001-.003v-.002s0-.002.989-.146l-.99.144A1 1 0 012 11.9z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.34 5.373l-13.2 14.5-1.48-1.346 13.2-14.5 1.48 1.346z"
      fill="currentColor"
    />
  </>
)

export default Icon
