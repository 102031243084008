import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <path
    d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm6.027 9.725c.006.124.008.249.008.374 0 3.822-2.91 8.23-8.23 8.23a8.188 8.188 0 01-4.435-1.3 5.805 5.805 0 004.283-1.198 2.895 2.895 0 01-2.703-2.009 2.9 2.9 0 001.307-.05 2.894 2.894 0 01-2.321-2.836V10.9c.39.216.836.347 1.31.361A2.892 2.892 0 016.352 7.4a8.213 8.213 0 005.963 3.022 2.893 2.893 0 014.93-2.638 5.792 5.792 0 001.836-.702 2.903 2.903 0 01-1.272 1.6 5.78 5.78 0 001.661-.455 5.874 5.874 0 01-1.442 1.498z"
    fill="currentColor"
  />
)

export default Icon
