export const getConferenceDailyPageContentTopStory = /* GraphQL */ `
  query getConferenceDailyPageContentTopStory {
    pageContentAssemblyCollection(
      where: {
        contentfulMetadata: {
          tags: { id_contains_all: "conferenceDailyTopStory" }
        }
      }
      limit: 1
      order: sys_publishedAt_DESC
    ) {
      items {
        sys {
          id
        }
        title
        body {
          json
        }
        slug
        featuredImage
      }
    }
  }
`
