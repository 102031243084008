import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <circle cx="8" cy="8" r="8" fill="#FF4D00" />
    <path
      stroke="#fff"
      strokeMiterlimit="10"
      d="M4.5 8.54l2.43 2.13L11.5 5.5"
    />
  </>
)

export default Icon
