import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm.648-13.22v.284c.39-.613.89-1.017 1.546-1.188a3.465 3.465 0 012.346.196c.76.34 1.116 1 1.297 1.771.133.566.163 1.142.162 1.722-.003 1.413-.004 2.826.001 4.24 0 .15-.042.198-.193.196-.708-.006-1.417-.008-2.125 0-.163.002-.203-.057-.203-.21l.001-.282c.004-1.22.008-2.44-.003-3.66a4.637 4.637 0 00-.107-1.004c-.13-.56-.508-.84-1.083-.846a2.26 2.26 0 00-.556.05c-.578.144-.823.582-.92 1.123-.045.26-.062.527-.063.791-.006 1.277-.006 2.555 0 3.832.002.16-.042.206-.2.205a96.509 96.509 0 00-2.125 0c-.16.001-.201-.052-.201-.208.004-2.539.004-5.078 0-7.617 0-.154.038-.207.2-.205.682.01 1.364.008 2.046 0 .15-.002.187.049.183.188-.005.202-.005.404-.003.622zM8.71 13.21v4.577c0 .207 0 .209-.209.209h-.797c-.443 0-.886 0-1.328.002-.136.002-.19-.031-.19-.183.003-2.553.003-5.107 0-7.66 0-.147.044-.185.184-.184.72.007 1.438.007 2.157 0 .146 0 .185.046.185.187-.003 1.018-.003 2.035-.002 3.052zm.189-5.751c0 .801-.645 1.444-1.448 1.444-.807 0-1.45-.637-1.451-1.44A1.443 1.443 0 017.447 6c.807 0 1.453.65 1.452 1.458z"
    fill="currentColor"
  />
)

export default Icon
