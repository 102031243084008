import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <path
      d="M16 4c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm0-2c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z"
      fill="currentColor"
    />
    <path
      d="M16.1 4.6h-.2c-.3 0-.5.2-.5.5V9c0 .3.2.5.5.5h.2c.3 0 .5-.2.5-.5V5.1c0-.2-.2-.5-.5-.5zM16 10.1c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6z"
      fill="currentColor"
    />
    <path
      d="M13.924 3.45A2 2 0 0010 4H9C7.4 4 6 5.4 6 7l-1.1 7L2 17.1C2 18.7 3.3 20 4.9 20h4.26c.398 1.184 1.493 2 2.84 2 1.347 0 2.442-.816 2.84-2h4.36c1.6 0 2.9-1.3 2.9-2.9l-3-3.1-.293-1.862a4.972 4.972 0 01-1.923.784L17.1 14.3c.1.4.2.7.5 1l2.1 2.3c-.2.2-.4.3-.7.3H4.9c-.3 0-.5-.1-.7-.3l2.1-2.3c.3-.3.4-.6.5-1l1.1-7c.1-.1.1-.2.1-.3 0-.5.5-1 1-1h2.416a5.019 5.019 0 012.508-2.55z"
      fill="currentColor"
    />
  </>
)

export default Icon
