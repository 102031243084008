import React, { ReactElement } from 'react'

const Icon = (): ReactElement => (
  <>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="40px"
      viewBox="0 0 110 90"
      x="0px"
      y="3px"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,55.000000) scale(0.120000,-0.120000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M192 438 c-18 -18 -15 -68 3 -68 9 0 15 9 15 25 0 24 3 25 54 25 54
0 55 0 58 -32 3 -29 7 -33 36 -36 l32 -3 0 -95 0 -94 -75 0 c-60 0 -75 -3 -75
-15 0 -12 15 -15 74 -15 103 0 106 4 106 136 l0 104 -36 40 c-36 40 -37 40
-108 40 -43 0 -77 -5 -84 -12z"
        />
        <path
          d="M126 324 c-31 -32 -33 -69 -6 -104 11 -14 20 -36 20 -50 0 -23 4 -25
40 -26 38 -1 40 0 40 28 0 16 9 38 19 49 29 32 14 100 -25 118 -38 17 -59 13
-88 -15z m84 -29 c26 -32 -13 -81 -47 -59 -23 14 -28 41 -13 59 16 19 44 19
60 0z"
        />
      </g>
    </svg>
  </>
)

export default Icon
