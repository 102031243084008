import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import RichTextRenderer from '../RichTextRender'
import ComponentLoader from '../Loader/ComponentLoader'
import ErrorPage from '../Error'
import analytics from '../../Helpers/segmentEventClickBlog'
import { useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 3,
    borderRadius: '18px 18px 8px 8px',
  },
}))

const TopStoryCard = ({ dataTopStory, error, loading, segmentUserId }) => {
  const theme = useTheme()
  const classes = useStyles()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  if (loading) return <ComponentLoader />

  if (error) return <ErrorPage />

  const handleClick = (item) => {
    analytics.track('Conference Daily', {
      userId: segmentUserId,
      clickSource: 'conference-news-feed-top-story',
      slug: item.slug,
      title: item.title,
    })
    window.open(`https://td.org/content/${item.slug}`, '_blank')
  }

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        image={dataTopStory[0]?.featuredImage?.[0]?.thumbnails?.webimage}
        alt={dataTopStory[0]?.title}
        sx={{
          height: 'auto',
          objectFit: 'cover',
          filter: 'blur(10px)',
          transition: 'filter 0.3s ease-out',
        }}
        onLoad={(e) => (e.target.style.filter = 'none')}
      />
      <CardContent sx={{ flex: 1, p: 3 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          sx={{ cursor: 'pointer' }}
          onClick={() => handleClick(dataTopStory[0])}
        >
          {dataTopStory[0]?.title}
        </Typography>
        <RichTextRenderer
          content={dataTopStory[0]?.body?.json}
          limit={isSmallScreen ? 250 : 600}
          doubleLine
        />
      </CardContent>
    </Card>
  )
}

export default TopStoryCard
