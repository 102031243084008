import { useState } from 'react'
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import RichTextRenderer from '../RichTextRender'
import ComponentLoader from '../Loader/ComponentLoader'
import ErrorPage from '../Error'
import analytics from '../../Helpers/segmentEventClickBlog'
import { useMediaQuery, useTheme } from '@mui/material'
import Ad from '../../Ad'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
    borderRadius: '18px 18px 8px 8px',
    height: '100%',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: theme.shadows[6],
    },
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 140,
    height: 140,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.up('md')]: {
      width: 140,
      height: 140,
    },

    [theme.breakpoints.up('lg')]: {
      width: 140,
      height: 140,
    },
  },
  image: {
    width: '120px',
    height: '110px',
    objectFit: 'cover',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

const BlogsSection = ({ content, error, loading, segmentUserId }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [visibleCount, setVisibleCount] = useState(10)

  if (loading) return <ComponentLoader />

  if (error) return <ErrorPage />

  const loadMore = () => {
    setVisibleCount((prev) => prev + 10)
  }

  const handleClick = (item) => {
    analytics.track('Conference Daily', {
      userId: segmentUserId,
      clickSource: 'conference-news-feed',
      slug: item.slug,
      title: item.title,
    })
    window.open(`https://td.org/content/${item.slug}`, '_blank')
  }

  return (
    <Grid container spacing={3}>
      {content.slice(0, visibleCount).map((item, index) => (
        <>
          <Grid item xs={12} key={index}>
            <Card className={classes.card}>
              <Box className={classes.imageContainer}>
                <CardMedia
                  component="img"
                  image={item?.featuredImage?.[0]?.thumbnails?.webimage}
                  alt={item?.title}
                  className={classes.image}
                  sx={{
                    filter: 'blur(10px)',
                    transition: 'filter 0.3s ease-out',
                  }}
                  onLoad={(e) => (e.target.style.filter = 'none')}
                />
              </Box>
              <CardContent
                className={classes.content}
                onClick={() => handleClick(item)}
              >
                <Typography variant="h6">{item?.title}</Typography>
                <Typography variant="body2">
                  <RichTextRenderer
                    content={item?.body?.json}
                    limit={isSmallScreen ? 100 : isMediumScreen ? 200 : 230}
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {index !== 0 && index % 6 === 0 && (
            <Box width="100%" display="flex" justifyContent="center" my={4}>
              <Ad />
            </Box>
          )}
        </>
      ))}

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        {content.length > visibleCount && (
          <Button
            variant="contained"
            color="info"
            onClick={() => loadMore()}
            sx={{ mt: 4, display: 'block', mx: 'auto', cursor: 'pointer' }}
          >
            Load more
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default BlogsSection
